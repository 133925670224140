import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  title: {
    pageError: 'Something went wrong',
    pageLoading: 'Loading...',
    page403: 'Hmm... Access denied!',
    sideBar: {
      rulesManagement: 'Rule Management',
    },
  },
  button: {
    tryAgain: 'Try again',
    takeMeHome: 'Take me home',
  },
  message: {
    error: {
      default: 'Ooops! Something went wrong',
      accessDenied: "It looks like you don't have permission to access page. Feeling lost?",
      optimizelyNotLoaded:
        'Sorry! The connection to the optimizely service was not successful. Please try again',
    },
  },
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
    examplePageName: 'Guarana is the best POD!!!!!!',
  },
  app: {
    titleRulesManagement: 'Rule management',
    descriptionRulesManagement: 'Create and consult image recognition rules.',
  },
};

export default enUS;
