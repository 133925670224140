import { ThemeProvider } from '@hexa-ui/theme';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { createFeatureToggleServiceV2, useHubTheme } from 'admin-portal-shared-services';
import { BrowserRouter } from 'react-router-dom';
import { SideBar } from './components/SideBar';
import { VISION_HUB_CLASSNAME_PREFIX, VISION_HUB_THEME } from './consts/theme';
import { AppThemeProvider } from './providers/AppThemeProvider';
import { EnvConfig, EnvProvider } from './providers/EnvProvider';
import { IntlProvider } from './providers/IntlProvider';
import { Router } from './routes/Router';
import { OptimizelyConfig } from './interfaces/Optimizely';
import { OPTIMIZELY_PROJECT_BEES_ONTAP } from './consts/optimizely';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: VISION_HUB_CLASSNAME_PREFIX,
    seed: VISION_HUB_CLASSNAME_PREFIX,
  });

  const optimizelyConfig: OptimizelyConfig = {
    projectName: OPTIMIZELY_PROJECT_BEES_ONTAP,
    sdkKey: props.OPTIMIZELY_KEY,
  };

  createFeatureToggleServiceV2(optimizelyConfig.projectName, optimizelyConfig.sdkKey);

  useHubTheme(VISION_HUB_THEME);

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={VISION_HUB_THEME}>
          <AppThemeProvider>
            <IntlProvider>
              <BrowserRouter>
                <SideBar />
                <Router />
              </BrowserRouter>
            </IntlProvider>
          </AppThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
