import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  title: {
    pageError: 'Algo deu errado',
    pageLoading: 'Carregando...',
    page403: 'Acesso negado',
    sideBar: {
      rulesManagement: 'Gerenciamento de Regras',
    },
  },
  button: {
    tryAgain: 'Tente novamente',
    takeMeHoke: 'Me leve para Home',
  },
  message: {
    error: {
      default: 'Ooops! Algo deu errado',
      accessDenied:
        'Parece que você não tem permissão para acessar essa página. Se sentindo perdido?',
      optimizelyNotLoaded:
        'Desculpe! A conexão com o serviço optimizely não foi realizada com sucesso. Por favor, tente novamente',
    },
  },
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em Português',
    examplePageName: 'Guarana é a melhor POD!!!!!!',
  },
  app: {
    titleRulesManagement: 'Gerenciamento de regras',
    descriptionRulesManagement: 'Crie e consulte as regras de reconhecimento de imagem.',
  },
};

export default ptBR;
