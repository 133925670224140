import MessageMap from '../i18n.d';

const es419: MessageMap = {
  title: {
    pageError: 'Algo salió mal',
    pageLoading: 'Cargando...',
    page403: 'Hmm... Acceso denegado!',
    sideBar: {
      rulesManagement: 'Gestión de Reglas',
    },
  },
  button: {
    tryAgain: 'Inténtalo de nuevo',
    takeMeHome: 'Llévame a Home',
  },
  message: {
    error: {
      default: 'Ooops! Lo siento, algo salió mal',
      accessDenied: 'Parece que no tienes permiso para acceder a la página. ¿Te sientes perdido?',
      optimizelyNotLoaded:
        '¡Perdón!. La conexión con el servicio de optimización no ha tenido éxito. inténtelo de nuevo más tarde.',
    },
  },
  test: {
    translate: 'Traducir',
    missing: 'Solo opción en español disponible',
    examplePageName: 'Guarana és la mejor POD!!!!!!',
  },
  app: {
    titleRulesManagement: 'Gestión de reglas',
    descriptionRulesManagement: 'Crear y consultar reglas de reconocimiento de imágenes.',
  },
};

export default es419;
