import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageLoading } from '../pages/PageLoading';

const Switch = lazy(() => import('../pages/Switch/Switch'));
const Page403 = lazy(() => import('../pages/Page403/Page403'));

export const Router = (): JSX.Element => {
  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        <Route path="/vision" element={<Switch />} />
        <Route path="/vision/access-denied" element={<Page403 />} />
      </Routes>
    </Suspense>
  );
};
